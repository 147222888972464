
export default {
  components: {
    Notifications: () => import("./components/notifications.vue"),
    Search: () => import("./components/SearchDialog.vue"),
  },
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    idCommunity: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    isBackNav: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    withNotification: {
      type: Boolean,
      default: false,
    },
    type: {
      type: "base" | "back" | "home",
      default: "base",
    },
  },
  data: () => ({
    drawer: false,
    BASE_URL: process.env.BASE_URL,
    activeIndex: "",
    search: "",
    iframe_url: "",
    chat_modal: false,
    interval: null,
    selected: "",
  }),
  mounted() {
    const split = window.location.host.split(".");
    if (split && split.length > 0) {
      this.selected = split[0];
    }
  },
  methods: {
    handleIconType() {
      switch (this.type) {
        case "back":
          return {
            icon: "chevron-left",
          };
        case "home":
          return {
            icon: "home",
          };

        default:
          return {
            icon: "menu",
          };
      }
    },
    handleTitleClick(pos) {
      switch (pos) {
        case "icon":
          if (this.type === "base") {
            this.drawer = !this.drawer;
          } else if (this.type === "home") {
            this.$goHome();
          } else {
            window.open(
              process.env.BASE_COMMUNITY + "mb/community/" + this.idCommunity,
              "_top"
            );
          }
          break;
        case "title":
          if (this.type === "base") {
            this.$router.push(this.localeLocation("/"));
          } else if (this.type === "home") {
            this.$goHome();
          } else {
            window.open(
              process.env.BASE_COMMUNITY + "mb/community/" + this.idCommunity,
              "_top"
            );
          }
          break;

        default:
          break;
      }
    },
    handleVariant(variant) {
      switch (variant) {
        case "primary":
          return {
            style: {
              backgroundColor: "#00c9ff",
              color: "#fff",
            },
          };
        case "secondary":
          return {
            style: {
              backgroundColor: "#F7921E",
              color: "#ffff",
            },
          };
        default:
          return {
            style: {
              backgroundColor: "#ffff",
              color: "#00c9ff",
            },
          };
      }
    },
    handleMenu(val) {
      const res =
        this.title === val ? this.$router.push(this.localeLocation("/")) : val;
      switch (res) {
        case "community":
          return this.$goCommunity();
        case "post":
          return this.$goPost();
        case "expert":
          return this.$goExpert();
        case "rute-ganjil-genap":
          return this.$goHome("rute-ganjil-genap");
        case "charging-station":
          return this.$goHome("charging-station");
        case "privacy-policy":
          return this.$goHome("privacy-policy");
      }
    },
    querySearch(queryString, cb) {
      const links = this.links;
      const results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    activeMenu(val) {
      const path = this.$route.path.split(".");
      if (path && path.length > 0) {
        const clean_path = path[0].split("/").join("");
        if (clean_path === val) {
          return true;
        }
      }
      return false;
    },
    startTimer() {
      this.interval = setInterval(() => {
        this.fetchNotifications();
        this.fetchMailbox();
      }, 30000);
    },
    async fetchMailbox() {
      await this.$store.dispatch("public/getMailbox").catch((err) => {
        throw new Error(err);
      });
    },
    async fetchNotifications() {
      await this.$store.dispatch("public/getNotifications").catch((err) => {
        clearInterval(this.interval);
        throw new Error(err);
      });
    },
  },
};
